.aboutus-section{
    margin-top: 100px;
    width: 100%;
    padding: 20px;

    .aboutus-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 50px;

        .aboutus-sec {
            width: 50%;
            margin: 0 0 50px 50px;

            .aboutus-subhead{
                text-align: center;
                color: #7b68ee;
                font-size: 18px;
                font-style: italic;
                font-weight: 600;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            }
        
            .aboutus-head {
                text-align: center;
                color: #404040;
                font-size: 40px;
                font-weight: 800;
                font-family: cursive;
                margin-bottom: 20px;
            }

            .aboutus-desc {
                font-size: 18px;
                color: #404040;
                font-family: 'Times New Roman', Times, serif;
                font-style: italic;
                text-align: justify;
                // direction: rtl; /* Add this line for right-to-left */
            }
            .rtl {
                direction: rtl;
              }
              
              .ltr {
                direction: ltr;
              }
            .aboutus-reasons {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;            

                .about {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }

                .about-bar {
                    height: 7px;
                    border-radius: 5px;
                    background-color: #ddddff;
                    width: 100%;

                    .about-progress1 {
                        height: 100%;
                        width: 75%;
                        background: linear-gradient(to right,#7b68ee,#afa6e0 10%,#7b68ee 20%, #afa6e0 30%, #7b68ee 40%,#afa6e0 50%,#7b68ee 60%,#afa6e0 70%,#7b68ee 80%,#afa6e0 90%,#7b68ee 100%);
                        border-radius: 5px;
                    }
                    .about-progress2 {
                        height: 100%;
                        width: 70%;
                        background: linear-gradient(to right,#7b68ee,#afa6e0 10%,#7b68ee 20%, #afa6e0 30%, #7b68ee 40%,#afa6e0 50%,#7b68ee 60%,#afa6e0 70%,#7b68ee 80%,#afa6e0 90%,#7b68ee 100%);
                        border-radius: 5px;
                    }
                    .about-progress3 {
                        height: 100%;
                        width: 80%;
                        background: linear-gradient(to right,#7b68ee,#afa6e0 10%,#7b68ee 20%, #afa6e0 30%, #7b68ee 40%,#afa6e0 50%,#7b68ee 60%,#afa6e0 70%,#7b68ee 80%,#afa6e0 90%,#7b68ee 100%);
                        border-radius: 5px;
                    }
                    .about-progress4 {
                        height: 100%;
                        width: 90%;
                        background: linear-gradient(to right,#7b68ee,#afa6e0 10%,#7b68ee 20%, #afa6e0 30%, #7b68ee 40%,#afa6e0 50%,#7b68ee 60%,#afa6e0 70%,#7b68ee 80%,#afa6e0 90%,#7b68ee 100%);
                        border-radius: 5px;
                    }
                }
            }

            @media (max-width: 1570px) {
                .aboutus-head {
                    font-size: 35px;
                }
            }
            @media (max-width: 1400px) {
                .aboutus-head {
                    font-size: 30px;
                }
            }
            @media (max-width: 1256px) {
                .aboutus-subhead{
                    font-size: 17px;
                }
                .aboutus-head {
                    font-size: 25px;
                }
                .aboutus-desc {
                    font-size: 17px;
                }
            }
            @media (max-width: 1108px) {
                .aboutus-subhead{
                    font-size: 16px;
                }
                .aboutus-head {
                    font-size: 20px;
                }
                .aboutus-desc {
                    font-size: 16px;
                }
            }
            @media (max-width: 1005px) {
                .aboutus-subhead{
                    font-size: 18px;
                }
                .aboutus-head {
                    font-size: 40px;
                }
                .aboutus-desc {
                    font-size: 18px;
                }
            }
            @media (max-width: 888px) {
                .aboutus-head {
                    font-size: 35px;
                }
            }
            @media (max-width: 802px) {
                .aboutus-head {
                    font-size: 30px;
                }
            }
            @media (max-width: 717px) {
                .aboutus-subhead{
                    font-size: 17px;
                }
                .aboutus-head {
                    font-size: 25px;
                }
                .aboutus-desc {
                    font-size: 17px;
                }
            }
            @media (max-width: 632px) {
                .aboutus-subhead{
                    font-size: 16px;
                }
                .aboutus-head {
                    font-size: 20px;
                }
                .aboutus-desc {
                    font-size: 16px;
                }
            }
            @media (max-width: 575px) {
                .aboutus-subhead{
                    font-size: 18px;
                }
                .aboutus-head {
                    font-size: 40px;
                }
                .aboutus-desc {
                    font-size: 18px;
                }
            }
        }

        & img {
            width: 50%;
            border-radius: 5px;
        }

        @media (max-width: 1005px) {
            flex-wrap: wrap;

            .aboutus-sec {
                width: 100%;
                margin-top: 50px;
            }

            & img {
                width: 100%;
            }
        }

        @media (max-width: 575px) {
            .aboutus-sec {
                margin: 0;
            }
        }
    }

    @media (max-width: 575px) {
        .aboutus-container {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            gap: 0
        }
    }
}