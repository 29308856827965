.home {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.language-toggle {
    position: fixed;
    top: 20px;
    right: 20px;
  }
  
  .language-toggle button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .language-toggle img {
    width: 30px;
    height: 30px;
  }
  