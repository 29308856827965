.carousel-container {
    width: 100%;

    .carousel-slide {
        img {
            height: 50vw;
            width: 100%;
        }
    }
}
  