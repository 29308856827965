.contact{
    margin-top: 100px;
    width: 100%;
    padding: 20px;

    .contact-form {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      color: #404040;
        font-size: 40px;
        font-weight: 800;
        font-family: cursive;
        margin-bottom: 20px;
    }
    .social-media-icons {
      display: flex;
      flex-direction: column;
  }
  
  .icon-link {
      margin-bottom: 10px; /* Adjust the margin as needed */
  }
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 8px;
        font-weight: bold;
      }
  
      input,
      textarea {
        margin-bottom: 16px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        width: 100%; /* Make the input fields take full width */
      }
  
      textarea {
        resize: vertical;
      }
  
      button {
        background-color: #7b68ee;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s;
        width: 100%; /* Make the button take full width */
  
        &:hover {
          background-color: #5441c2;
        }
      }
    }
  }  
}